import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './InfoCard.module.scss'
import Button from 'components/button/Button'
import Skeleton from 'components/skeleton/Skeleton'
import useI18n from 'common-hooks/useI18n'

const COPY_ICON = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 330 330" xmlSpace="preserve"><path fill="currentColor" d="M35 270h45v45c0 8.284 6.716 15 15 15h200c8.284 0 15-6.716 15-15V75c0-8.284-6.716-15-15-15h-45V15c0-8.284-6.716-15-15-15H35c-8.284 0-15 6.716-15 15v240c0 8.284 6.716 15 15 15zm245 30H110V90h170v210zM50 30h170v30H95c-8.284 0-15 6.716-15 15v165H50V30z"/><path fill="currentColor" d="M155 120c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15s-6.716-15-15-15h-80zM235 180h-80c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15s-6.716-15-15-15zM235 240h-80c-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15h80c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15z"/></svg>

function InfoCard({ title, cta, onCtaClick, secondaryCta, onSecondaryCtaClick, data, className, children, loading, canBeNull, wide, auto }) {
  const [showAllData, setShowAllData] = useState(false);
  const { i18n } = useI18n();

  const renderLinkOrText = (value, key) => {
    if (key.toLowerCase() === 'customer id') {
      return <a className={styles.link} href={`/order-grid?customerId=${value}`} title='All orders for this customer id' target="_blank" rel="noreferrer">{value}</a>
    }
    if (key.toLowerCase() === 'customer id_p') {
      return <a className={styles.link} href={`/customers/${value}/prescriptions`} title='All prescriptions for this customer' target="_blank" rel="noreferrer">{value}</a>
    }
    if (value?.startsWith?.('https://eyewaprescriptions.s3')) {
      return <a href={value} target="_blank" rel="noreferrer"><img className={cx(styles.image)} src={value} alt="" /></a>
    }
    if (value?.startsWith?.('http')) {
      return <a className={styles.link} href={value} target="_blank" rel="noreferrer">{i18n('Visit')}</a>
    } else {
      return value;
    }
  }

  const handleCopy = async (e, value) => {
    e.stopPropagation();
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(value);
    } else {
        const textArea = document.createElement("textarea");
        textArea.value = value;
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }
  }

  return (
    <div className={cx(styles.wrapper, className, { [styles.wrap]: showAllData, [styles.wide]: wide, [styles.auto]: auto })} onClick={() => setShowAllData(!showAllData)}>
      {(!!title || !!cta || !!secondaryCta) && <div className={cx(styles.header)}>
        <h2 className={cx(styles.title)}>{title}</h2>
        <div className={styles.buttons}>
          {!!secondaryCta && <Button size="small" theme="secondary" onClick={onSecondaryCtaClick}>{secondaryCta}</Button>}
          {!!cta && <Button size="small" theme="secondary" onClick={onCtaClick}>{cta}</Button>}
        </div>
      </div>}
      {!!data && <ul className={cx(styles.list)}>
        {Object.keys(data).map(key => (
          <li className={cx(styles.item)} key={key}>
            <strong className={cx(styles.term)}>{key}</strong>
            {loading ? <Skeleton /> : <span className={cx(styles.def)}>{data[key] === undefined || data[key] === null ? '' : renderLinkOrText(data[key], key) || (canBeNull ? <Skeleton /> : '-')}</span>}
            <button className={cx(styles.copy)} onClick={(e) => handleCopy(e, data[key])}>{COPY_ICON}</button>
          </li>
        ))}
      </ul>}
      {children}
    </div>
  )
}

InfoCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  className: PropTypes.string,
  canBeNull: PropTypes.bool
}

export default InfoCard
